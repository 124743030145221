import { createPinia } from "pinia";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "./style.css";
import { Amplify } from "aws-amplify";
import { createI18n } from "vue-i18n";
import authConfig from "./configs/authConfig";
import cloneDeep from "lodash.clonedeep";
import { VueQueryPlugin } from "@tanstack/vue-query";
import * as Sentry from "@sentry/vue";
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";

Amplify.configure(authConfig);

const pinia = createPinia();
pinia.use(({ store }) => {
	const initialState = cloneDeep(store.$state);
	store.$reset = () => store.$patch(cloneDeep(initialState));
});

const messages = Object.fromEntries(
	Object.entries(
		import.meta.glob<{ default: any }>("./locales/*.y(a)?ml", {
			eager: true,
		}),
	).map(([key, value]) => {
		const yaml = key.endsWith(".yaml");
		const out = key.split("/")[2].slice(0, yaml ? -5 : -4);
		return [out, value.default];
	}),
);

const i18n = createI18n({
	locale: "en",
	fallbackLocale: "en",
	legacy: false,
	missingWarn: true,
	warnHtmlMessage: false,
	warnHtmlInMessage: "off",
	silentFallbackWarn: true,
	silentTranslationWarn: true,
	fallbackWarn: false,
	messages,
	datetimeFormats: {
		en: {
			short: {
				year: "numeric",
				month: "numeric",
				day: "numeric",
			},
			long: {
				year: "numeric",
				month: "2-digit",
				day: "2-digit",
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
			},
		},
	},
});

const app = createApp(App);

Sentry.init({
	app,
	dsn: "https://52bfcb1acf889542be4123db813877f4@o4506507993481216.ingest.sentry.io/4506508021202944",
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			tracePropagationTargets: [
				// "localhost", //comment out for development
				"https://rc.balelink.com",
				"https://beta.balelink.com",
			],
		}),
		new Sentry.Replay({
			maskAllText: false,
			blockAllMedia: false,
		}),
		new HttpClientIntegration({
			failedRequestStatusCodes: [[400, 599]],
			failedRequestTargets: ["amazonaws", "agco-randers"],
		}) as never,
	],
	// Performance Monitoring
	tracesSampleRate: 1.0,
	// Session Replay
	// replaysSessionSampleRate: 1.0, //comment out for development
	replaysOnErrorSampleRate: 1.0, //comment out for development
	// Captures cookies and headers
	sendDefaultPii: true,
});

app.use(router);
app.use(pinia);
app.use(i18n);
app.use(VueQueryPlugin);
app.use(PrimeVue, {
	unstyled: true,
	zIndex: {
		modal: 9999, //override default for toasts
	},
});
app.use(ToastService);

app.mount("#app");
