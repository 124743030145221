import {
	signIn as CognitoSignIn,
	signOut as CognitoSignOut,
	getCurrentUser,
	decodeJWT,
	fetchAuthSession,
	type AuthUser,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import { ref, computed, watch } from "vue";

export type BaleLinkUser = {
	username: string;
	id: string;
	attributes: {
		email: string;
		email_verified: boolean;
		sub: string;
		preferred_username: string;
		"custom:accountid": string;
	};
};

/**
 * Currently authenticated user.
 * If this is set to null, the app will be force redirected to the /login page.
 */
const currentUser = ref<BaleLinkUser | AuthUser | null>(null);

/**
 * Cached JWT used for authenticated HTTP requests.
 * BaleLink's backend API authenticates using ID tokens.
 * This ref is refreshed on every page navigation.
 */
const cachedIdToken = ref<string | null>(null);

/**
 * Cached JWT used for authenticated HTTP requests.
 * This access token is used to access external APIs such as Randers field import API.
 * This ref is refreshed on every page navigation.
 */
const cachedAccessToken = ref<string | null>(null);

/**
 * Cached organization ID used for authenticated admin support HTTP requests.
 * This string is only used by SuperAdmins to access organization specific data. It is
 * passed as a header (X-Organization-Id) in the HTTP request. It is stored in localstorage
 * between sessions or reset to the current user on login.
 */
const adminOrganizationId = ref<string | null>(null);

export function useAuth() {
	/**
	 * Currently unused.
	 * Sign in is done using AWS Amplify UI's authenticator component.
	 * This can be used to replace it if we decide to go headless.
	 */
	async function signIn(
		username: string,
		password: string,
	): Promise<BaleLinkUser> {
		try {
			const { isSignedIn, nextStep } = await CognitoSignIn({
				username,
				password,
			});
			if (isSignedIn) {
				currentUser.value = await getCurrentUser();
			}
			return currentUser.value as BaleLinkUser;
		} catch (error) {
			console.error(`Sign in error: ${error}`);
			throw error;
		}
	}

	async function signOut() {
		try {
			await CognitoSignOut();
			currentUser.value = null;
			adminOrganizationId.value = null;
		} catch (error) {
			console.error("error signing out: ", error);
		}
	}

	// Required to make currentUser reactive whenever something changes in the auth state
	Hub.listen("auth", async data => {
		const { payload } = data;
		if (payload.event === "signedIn") {
			currentUser.value = await getCurrentUser();
			adminOrganizationId.value = await getOrganizationId();
		}
		if (payload.event === "signedOut") {
			currentUser.value = null;
			adminOrganizationId.value = null;
		}
	});

	async function loadCurrentUser() {
		currentUser.value = await getCurrentUser();
	}

	const isAuthorizedUser = computed(() => {
		if (!currentUser.value) return false; // Prevent post sign out errors

		if (!cachedIdToken.value) {
			refreshToken("id");
		}

		const userInfo = decodeJWT(cachedIdToken.value ?? "");
		const groups = userInfo?.payload["cognito:groups"] as string[];

		return (
			groups.includes("AccountsOwner") || groups.includes("SuperAdmins")
		);
	});

	const isAdmin = computed(() => {
		if (!currentUser.value) return false; // Prevent post sign out errors

		if (!cachedIdToken.value) {
			refreshToken("id");
		}

		const userInfo = decodeJWT(cachedIdToken.value ?? "");
		const groups = userInfo?.payload["cognito:groups"] as string[];

		return groups.includes("SuperAdmins");
	});

	return {
		signIn,
		signOut,
		currentUser,
		cachedIdToken,
		cachedAccessToken,
		loadCurrentUser,
		isAuthorizedUser,
		isAdmin,
		adminOrganizationId,
	};
}

export async function refreshToken(type: "id" | "access") {
	// Fetch the current set of tokens
	// Amplify will automatically refresh the tokens if they are expired
	const session = await fetchAuthSession();
	let newToken = "";
	if (type === "id") {
		cachedIdToken.value = session.tokens?.idToken?.toString() ?? null;
		if (!cachedIdToken.value) {
			throw new Error("Failed to refresh token.");
		}
		newToken = cachedIdToken.value;
	} else {
		cachedAccessToken.value =
			session.tokens?.accessToken.toString() ?? null;
		if (!cachedAccessToken.value) {
			throw new Error("Failed to refresh token.");
		}
		newToken = cachedAccessToken.value;
	}
	return newToken;
}

export async function getOrganizationId() {
	if (!currentUser.value) return null;

	if (!cachedIdToken.value) {
		await refreshToken("id");
	}

	const userInfo = decodeJWT(cachedIdToken.value ?? "");
	const groups = userInfo?.payload["cognito:groups"] as string[];

	if (!groups.includes("SuperAdmins")) {
		return null;
	}

	return userInfo?.payload["custom:accountid"] as string;
}
