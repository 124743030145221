/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * GeoJSon object
 * The coordinate reference system for all GeoJSON coordinates is a geographic coordinate reference system, using the World Geodetic System 1984 (WGS 84) datum, with longitude and latitude units of decimal degrees. This is equivalent to the coordinate reference system identified by the Open Geospatial Consortium (OGC) URN An OPTIONAL third-position element SHALL be the height in meters above or below the WGS 84 reference ellipsoid. In the absence of elevation values, applications sensitive to height or depth SHOULD interpret positions as being at local ground or sea level.
 */
export interface GeoJsonObject {
	type:
		| "Feature"
		| "FeatureCollection"
		| "Point"
		| "MultiPoint"
		| "LineString"
		| "MultiLineString"
		| "Polygon"
		| "MultiPolygon"
		| "GeometryCollection";
	/** A GeoJSON object MAY have a member named "bbox" to include information on the coordinate range for its Geometries, Features, or FeatureCollections. The value of the bbox member MUST be an array of length 2*n where n is the number of dimensions represented in the contained geometries, with all axes of the most southwesterly point followed by all axes of the more northeasterly point. The axes order of a bbox follows the axes order of geometries. */
	bbox?: number[];
}

/** Abstract type for all GeoJSon object except Feature and FeatureCollection */
export type Geometry = GeoJsonObject & {
	type: "Point" | "MultiPoint" | "LineString" | "MultiLineString" | "Polygon" | "MultiPolygon" | "GeometryCollection";
};

/** Abstract type for all GeoJSon 'Geometry' object the type of which is not 'GeometryCollection' */
export type GeometryElement = Geometry & {
	type: "Point" | "MultiPoint" | "LineString" | "MultiLineString" | "Polygon" | "MultiPolygon";
};

/** GeoJSon 'Feature' object */
export type Feature = GeoJsonObject & {
	geometry: Geometry;
	properties: object | null;
	id?: number | string;
};

/** GeoJSon 'FeatureCollection' object */
export type FeatureCollection = GeoJsonObject & {
	features: Feature[];
};

/**
 * GeoJSon fundamental geometry construct.
 * A position is an array of numbers. There MUST be two or more elements. The first two elements are longitude and latitude, or easting and northing, precisely in that order and using decimal numbers. Altitude or elevation MAY be included as an optional third element.
 * Implementations SHOULD NOT extend positions beyond three elements because the semantics of extra elements are unspecified and ambiguous. Historically, some implementations have used a fourth element to carry a linear referencing measure (sometimes denoted as "M") or a numerical timestamp, but in most situations a parser will not be able to properly interpret these values. The interpretation and meaning of additional elements is beyond the scope of this specification, and additional elements MAY be ignored by parsers.
 * @maxItems 3
 * @minItems 2
 */
export type Position = number[];

/**
 * GeoJSon fundamental geometry construct, array of two or more positions.
 * @minItems 2
 */
export type LineStringCoordinates = Position[];

/**
 * A linear ring is a closed LineString with four or more positions.
 * The first and last positions are equivalent, and they MUST contain identical values; their representation SHOULD also be identical.
 * A linear ring is the boundary of a surface or the boundary of a hole in a surface.
 * A linear ring MUST follow the right-hand rule with respect to the area it bounds, i.e., exterior rings are counterclockwise, and holes are clockwise.
 * @minItems 4
 */
export type LinearRing = Position[];

/** GeoJSon geometry */
export type Point = GeometryElement & {
	type: "Point";
	/**
	 * GeoJSon fundamental geometry construct.
	 * A position is an array of numbers. There MUST be two or more elements. The first two elements are longitude and latitude, or easting and northing, precisely in that order and using decimal numbers. Altitude or elevation MAY be included as an optional third element.
	 * Implementations SHOULD NOT extend positions beyond three elements because the semantics of extra elements are unspecified and ambiguous. Historically, some implementations have used a fourth element to carry a linear referencing measure (sometimes denoted as "M") or a numerical timestamp, but in most situations a parser will not be able to properly interpret these values. The interpretation and meaning of additional elements is beyond the scope of this specification, and additional elements MAY be ignored by parsers.
	 */
	coordinates: Position;
};

/** GeoJSon geometry */
export type MultiPoint = GeometryElement & {
	coordinates: Position[];
};

/** GeoJSon geometry */
export type LineString = GeometryElement & {
	/** GeoJSon fundamental geometry construct, array of two or more positions. */
	coordinates: LineStringCoordinates;
};

/** GeoJSon geometry */
export type MultiLineString = GeometryElement & {
	coordinates: LineStringCoordinates[];
};

/** GeoJSon geometry */
export type Polygon = GeometryElement & {
	coordinates: LinearRing[];
};

/** GeoJSon geometry */
export type MultiPolygon = GeometryElement & {
	coordinates: LinearRing[][];
};

/** GeoJSon geometry collection GeometryCollections composed of a single part or a number of parts of a single type SHOULD be avoided when that single part or a single object of multipart type (MultiPoint, MultiLineString, or MultiPolygon) could be used instead. */
export type GeometryCollection = Geometry & {
	/** @minItems 0 */
	geometries: GeometryElement[];
};

/** Get ISOXML Response */
export interface ISO11783TaskData {
	children?: object[];
	versionMajor?: string;
	versionMinor?: string;
	managementSoftwareManufacturer?: string;
	managementSoftwareVersion?: string;
	taskControllerManufacturer?: string;
	taskControllerVersion?: string;
	dataTransferOrigin?: string;
	lang?: string;
}

/** Send a request to convert an import format to an export format */
export interface ConvertFileRequest {
	/** @format binary */
	file: File;
	/** Enum that contains all the valid valids for the import-format */
	"import-format"?: SupportedImportFormat;
	/** Enum that contains all the valid valids for the import-format */
	"export-format"?: SupportedExportFormat;
}

/** Enum that contains all the valid valids for the import-format */
export enum SupportedImportFormat {
	AGLEADER = "AGLEADER",
	JD = "JD",
	AUTOGUIDE1000 = "AUTOGUIDE_1000",
	AUTOGUIDE3000 = "AUTOGUIDE_3000",
	GEOJSON = "GEOJSON",
	ISOXMLV33 = "ISOXML_V33",
	ISOXMLV42 = "ISOXML_V42",
	ISOXMLV43 = "ISOXML_V43",
	PFTM = "PFTM",
	RAVEN = "RAVEN",
	SHAPE = "SHAPE",
	TELE_PAC = "TELE_PAC",
	TOPCON319 = "TOPCON_319",
	TOPCON320 = "TOPCON_320",
	TRIMBLE = "TRIMBLE",
}

/** Enum that contains all the valid valids for the import-format */
export enum SupportedExportFormat {
	AGLEADER = "AGLEADER",
	AUTOGUIDE1000 = "AUTOGUIDE_1000",
	AUTOGUIDE3000 = "AUTOGUIDE_3000",
	GEOJSON = "GEOJSON",
	ISOXMLV33 = "ISOXML_V33",
	ISOXMLV42 = "ISOXML_V42",
	ISOXMLV43 = "ISOXML_V43",
	PFTM = "PFTM",
	SHAPE = "SHAPE",
	TOPCON319 = "TOPCON_319",
	TOPCON320 = "TOPCON_320",
	TRIMBLE = "TRIMBLE",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
	securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
	data: D;
	error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = "https://balelinkv2.data-exchange-api.aws.agco-randers.dk";
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

	private baseApiParams: RequestParams = {
		credentials: "same-origin",
		headers: {},
		redirect: "follow",
		referrerPolicy: "no-referrer",
	};

	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
		Object.assign(this, apiConfig);
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected encodeQueryParam(key: string, value: any) {
		const encodedKey = encodeURIComponent(key);
		return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
	}

	protected addQueryParam(query: QueryParamsType, key: string) {
		return this.encodeQueryParam(key, query[key]);
	}

	protected addArrayQueryParam(query: QueryParamsType, key: string) {
		const value = query[key];
		return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
	}

	protected toQueryString(rawQuery?: QueryParamsType): string {
		const query = rawQuery || {};
		const keys = Object.keys(query).filter(key => "undefined" !== typeof query[key]);
		return keys
			.map(key => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
			.join("&");
	}

	protected addQueryParams(rawQuery?: QueryParamsType): string {
		const queryString = this.toQueryString(rawQuery);
		return queryString ? `?${queryString}` : "";
	}

	private contentFormatters: Record<ContentType, (input: any) => any> = {
		[ContentType.Json]: (input: any) =>
			input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
		[ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
		[ContentType.FormData]: (input: any) =>
			Object.keys(input || {}).reduce((formData, key) => {
				const property = input[key];
				formData.append(
					key,
					property instanceof Blob
						? property
						: typeof property === "object" && property !== null
						? JSON.stringify(property)
						: `${property}`,
				);
				return formData;
			}, new FormData()),
		[ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};

	protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
		return {
			...this.baseApiParams,
			...params1,
			...(params2 || {}),
			headers: {
				...(this.baseApiParams.headers || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
		if (this.abortControllers.has(cancelToken)) {
			const abortController = this.abortControllers.get(cancelToken);
			if (abortController) {
				return abortController.signal;
			}
			return void 0;
		}

		const abortController = new AbortController();
		this.abortControllers.set(cancelToken, abortController);
		return abortController.signal;
	};

	public abortRequest = (cancelToken: CancelToken) => {
		const abortController = this.abortControllers.get(cancelToken);

		if (abortController) {
			abortController.abort();
			this.abortControllers.delete(cancelToken);
		}
	};

	public request = async <T = any, E = any>({
		body,
		secure,
		path,
		type,
		query,
		format,
		baseUrl,
		cancelToken,
		...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const queryString = query && this.toQueryString(query);
		const payloadFormatter = this.contentFormatters[type || ContentType.Json];
		const responseFormat = format || requestParams.format;

		return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
			},
			signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
			body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
		}).then(async response => {
			const r = response as HttpResponse<T, E>;
			r.data = null as unknown as T;
			r.error = null as unknown as E;

			const data = !responseFormat
				? r
				: await response[responseFormat]()
						.then(data => {
							if (r.ok) {
								r.data = data;
							} else {
								r.error = data;
							}
							return r;
						})
						.catch(e => {
							r.error = e;
							return r;
						});

			if (cancelToken) {
				this.abortControllers.delete(cancelToken);
			}

			if (!response.ok) throw data;
			return data;
		});
	};
}

/**
 * @title BaleLink Data Exchange API
 * @version 1.0.0
 * @baseUrl https://balelinkv2.data-exchange-api.aws.agco-randers.dk
 *
 * API that offers endpoints to export/import data formats used for path planning
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	api = {
		/**
		 * No description
		 *
		 * @tags health
		 * @name IsAlive
		 * @summary Returns OK if alive
		 * @request GET:/api/health
		 * @secure
		 */
		isAlive: (params: RequestParams = {}) =>
			this.request<string, any>({
				path: `/api/health`,
				method: "GET",
				secure: true,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags formats
		 * @name GetSupportedImportFormats
		 * @summary Returns a list of supported import formats
		 * @request GET:/api/supported-import-formats
		 * @secure
		 */
		getSupportedImportFormats: (params: RequestParams = {}) =>
			this.request<SupportedImportFormat, void>({
				path: `/api/supported-import-formats`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags formats
		 * @name GetSupportedExportFormats
		 * @summary Returns a list of supported export formats
		 * @request GET:/api/supported-export-formats
		 * @secure
		 */
		getSupportedExportFormats: (params: RequestParams = {}) =>
			this.request<SupportedExportFormat, void>({
				path: `/api/supported-export-formats`,
				method: "GET",
				secure: true,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags files
		 * @name ConvertFileCreate
		 * @summary Takes a file and a supported-export-format to convert to, a optional supported-import-format can also be sent to use the dedicated importer, if not sent it uses the auto importer. Converts the file to the chosen format and returns it as a bytearray Also returns a Content-Disposition header describing the filename/filetype
		 * @request POST:/api/convert-file
		 * @secure
		 */
		convertFileCreate: (data: ConvertFileRequest, params: RequestParams = {}) =>
			this.request<File, void>({
				path: `/api/convert-file`,
				method: "POST",
				body: data,
				secure: true,
				type: ContentType.FormData,
				...params,
			}),
	};
}
