missingTags: Missing Tags
creationTime: Creation Time
creationLocation: Creation Location
currentLocation: Current Location
weightKg: Weight
avgMoisture: Average Moisture
peakMoisture: Peak Moisture
protein: Protein
ash: Ash
sugar: NFC
temperature: Temperature
measurementQuality: Measurement Quality
appliedPreservative: Applied Preservative
baleSize: Bale Size
rfv: RFV
adf: ADF
ndf: NDF
balerVin: Baler VIN
loadType: Load Type
fieldUIDs: Fields
3x3: 3x3
3x4: 3x4
4x4: 4x4
4ft: 4ft
5ft: 5ft
70x80: 70x80
70x120: 70x120
name: Name
email: Email
status: Status
resend: RE-SEND
sent: SENT
addNew: "Add New"
internalLoad: Internal Load
retailLoad: Retail Load
register: Register Account
cancel: Cancel
confirm: Confirm
ok: OK
save: Save
back: Back
close: Close
delete: Delete
search: Search
selectOne: Please select one
selectAccount: Select account to view
reports: Reports
deleteDialogTitle: Delete Filter
deleteDialogDescription: Are you sure you want to delete this filter? This is an irreversible action.
average: Average
minimum: Minimum
min: Min
maximum: Maximum
max: Max
total: Total
sampleSize: Sample Size
baleUnit: Bale(s)
weight: Weight
moisture: Average Moisture
frequency: Frequency
baleWeightDist: Bale Weight Distribution
baleMoistureDist: Bale Average Moisture Distribution
baleNum: Number of Bales
weightWet: Weight (Wet)
weightDry: Weight (Dry)
baleLen: Bale Length
keyValue: "{key}: {value}"
infoField: "{title}: {value}{unit}"
infoSpacedField: "{title}: {value} {unit}"
rangeField: "{title}: {min}{unit} - {max}{unit}"
graphAxisLabel: "{title} ({unit})"
valueUnit: "{value}{unit}"
valueSpaceUnit: "{value} {unit}"
valueUnitBracket: "{value} ({unit})"
selected: "{value} Selected"
optionalField: "{value} (Optional)"
range: Range
value: Value
filterName: Filter Name
loadingFiltersMsg: Loading Filters...
bales: Bales
baleDetails: Bale Details
manuallyScanned: " (Manually Scanned)"
moreDetails: More Details
lessDetails: Less Details
stackDetails: Stack Details
notAvailable: N/A
poweredBy: Powered By
available: Available
inactive: Inactive
error: Error
genericError: "An error occurred. Please try again, check your connection, or contact BaleLink support."
form:
    equipment: Equipment
    moisture: Moisture
    nutrition: Nutrition
report:
    reportTitle: Report
    totalBales: Total Bales
    length: Bale Length
    dataDate: Date Range of Dataset
    baleCount: Bale Count
    baleLenMin: Bale Length Minimum
    baleLenMax: Bale Length Maximum
    moistureAvg: Moisture Average
    histogramUserTip: "Click and drag to zoom in on the histogram. Double click to reset zoom."
filter:
    filter: Filter
    activeFilter: Active Filter
    selectFilter: "Select Filter"
    selectFilterSubtitle: "Select a filter preset to view bales of interest."
    createNewFilter: "Create New Filter"
    createFilter: "Create Filter"
    editFilter: "Edit Filter"
    saveFilter: "Save Filter"
    deleteFilter: "Delete Filter"
    deleteFilterSubtitle: "Are you sure you want to delete this filter?"
    minInput: "Enter the minimum value for {name} range."
    maxInput: "Enter the maximum value for {name} range."
    dateRangeDescription: "{title} can be filtered by either a moving time window over the last x number of days, or by a set time window with a fixed start and end date."
    dateRangeLastXDays: "Enter the number of the previous days to track"
    dateRangeInputNote: "Or select a date range"
    createFilterError: "Create Filter Error"
    editFilterError: "Edit Filter Error"
    filterNameExists: "A filter with this name already exists."
    deleteFilterError: "Delete Filter Error"
    autoReportError: "Filter is being used for automatic reports. Please remove it before deleting."
menu:
    yearSelectionDetailedTooltip: Select the crop years from which to view your bales. Multiple years can be selected, and the start of each crop year may be selected from the settings menu.
    fieldSelectionTooltip: Select the fields you would like to view.
    heatmapDisabledTooltip: Generating a heatmap requires a sufficient zoom level and a valid field in the field of view to be enabled. Please zoom in until individual bales are visible on your map and ensure you have a field created.
    reportsDisabledTooltip: Generating a report requires a valid filter to be selected. Please select the filters tab, select a filter by left clicking a filter card, then try again.
    homeDescription: Select the viewing option for your bales
    heatmapTitle: Heatmap
    heatmapSubtitleWithRFV: Visualize your bales based on weight, moisture, and RFV.
    heatmapSubtitle: Visualize your bales based on weight and moisture.
    filtersTitle: Filters
    filtersSubtitle: Customize your map view with filters.
    summaryTitle: Summary
    summarySubtitle: View an aggregated summary of your bales within the field of view.
    reportsTitle: Reports
    reportsSubtitle: View and print reports based on your filters.
    dateRangeTitle: Date Range
    dateRangeSubtitle: Select a date range to view your bales.
    fieldsTitle: Fields
    fieldsSubtitle: Draw fields on the map or import fields.
    managementSettings: Sub-Accounts and Balers
    userSettingsTitle: User Settings
    userSettingsSubtitle: View, edit & add users on this account.
    balerManagementTitle: Baler Settings
    balerManagementSubtitle: View, edit & add balers on this account.
    signOutTitle: Sign Out
    signOutSubtitle: Sign out of your account.
    loading: Loading...
    baleReporting: Bale Reporting
    helpfulInfo: Helpful Information
    userGuides: User Guides and More
    autoReports: Automated Reports
    autoReportsUnauthorized: Automated reports are only available to account owners
field:
    fields: Fields
    importField: Import Field
    createField: Create Field
    editField: Edit Field
    supportedFileFormats: Supported file formats
    fieldName: Field Name
    size: Size
    drawField: Draw Field
    drawFieldDesc: Click on the polygon icon in the toolbar to start drawing. Edit the shape of the field by selecting the edit button on the toolbar and move any vertex. Add additional points by clicking the plus signs. Remove vertices by clicking on it without movement. When editing, overwrite the boundaries by drawing a new polygon.
    fieldNameDesc: Specify the name of the field.
    fieldNameInputPlaceholder: Add the name of the field
    saveField: Save Field
    selectFields: Select Fields
    selectFieldsDesc: Select the fields you would like to view.
    selectedField: "Selected Field(s)"
    deleteField: "Delete Field"
    deleteFieldSubtitle: "Are you sure you want to delete this field?"
    createFieldError: "Create Field Error"
    editFieldError: "Edit Field Error"
    deleteFieldError: "Delete Field Error"
heatmap:
    heatmap: "Heatmap"
    heatmapSubtitle: "View weight or moisture data as a gradient heatmap"
    overlay: "Overlay"
    overlaySubtitle: "View data as a gradient heatmap."
    icon: "Icon"
    iconSubtitle: "View data as interactive color-coded icons."
    weight: "Weight"
    moisture: "Moisture"
    rfv: "RFV"
    selectView: "Select View"
    selectViewSubtitle: "Customize the way you visualize your bale heatmap."
cutting:
    viewCuttings: View Cuttings
    createNewCuttingTitle: "Set Up a New Cutting"
    cuttings: "Cuttings"
    fields: "{value} Fields"
    crops: Crops
    unassignedCrops: No Crops Assigned
    unassigned: Unassigned
    maxAllowableCuttings: "Maximum Allowable Cuttings: {value}"
    selectFields: "Select Fields"
    newCutting: "New Cutting"
    addNewCutting: "Add New Cutting"
    editCutting: "Edit Cutting"
    cuttingName: "Cutting Name"
    dateRange: "Cutting Start & End"
    addFields: "Add Fields"
    backToCutting: "Back to Cutting"
    cropType: "Crop Type"
    noCropType: "No Crop Type"
    selectYourCuttings: "Select your cuttings"
    cuttingYear: "Cutting Year"
    cuttingShowCLBTooltip: Show / hide bales outside of cuttings. Selecting a year will toggle this on by default.
    manageCuttingsTooltip: Create or edit your cuttings
    showCLB: "Show Unassigned Bales"
    hideCLB: "Hide Unassigned Bales"
    deleteCutting: "Delete Cutting"
    deleteCuttingSubtitle: "Are you sure you want to delete this cutting?"
    createCuttingError: "Create Cutting Error"
    editCuttingError: "Edit Cutting Error"
    deleteCuttingError: "Delete Cutting Error"
    toolbarSwapView: "Alternate between stack and Gantt chart views"
    toolbarMinimize: Minimize the toolbar to view more of the map
    noCuttingsMessage: Cuttings that take place within this year associated with this crop will be displayed here. Please associate your existing cuttings with the corresponding crop or select the "New Cutting" button to get started.
    noCuttingsToolbarMessage: No cuttings found. Please create a new cutting or select a different year.
    manageCuttings: Manage Cuttings
    manageCrops: Manage Crops
    deleteCrop: Delete Crop
    deleteCropSubtitle: Are you sure you want to delete this crop?
    deleteCropError: Failed to delete crop
    addNewCrop: Add New Crop
    editCrop: Edit Crop
    associatedCrop: Associated Crop
    cropAndFieldsRequiredMsg: "Cuttings associated with a crop requires at least 1 field to be selected."
    cropName: Crop Name
    cropNamePlaceholder: Enter crop name here
    noCrop: No Crop
    returnToCuttings: Return to Cuttings

settings:
    settings: "Settings"
    rfvTitle: "RFV / Nutrition"
    rfvEnabled: "RFV / Nutrition Enabled"
    rfvDisabled: "RFV / Nutrition Disabled"
    systemOfMeasurement: "System of Measurement"
    systemOfMesaurementDisclaimer: "Some elements or graphs may not properly convert until it has been re-opened."
    metric: "Metric (SI)"
    imperialUK: "Imperial (UK)"
    imperialUS: "Imperial (US Customary)"
    balelinkSettings: "BaleLink Settings"
userSettings:
    subtitle: "User Settings"
    newUser: "New User"
    addNewUser: "Add New User"
    editUser: "Edit User"
    deleteUser: "Delete User"
    userName: "User Name"
    userEmail: "User Email"
    namePlaceholder: "Enter user name here"
    emailPlaceholder: "Enter user email here"
    deleteUserWarning: "Are you sure you want to delete this user?"
    userExistsWarning: "**User already exists**"
    userExistsElsewhereWarning: "**User already exists in another organization**"
    userCreationGeneralError: "User creation failed. Please try again."
    userEditGeneralError: "User update failed. Please try again."
    userDeleteGeneralError: "User deletion failed. Please try again."
    doesNotExistError: "**User does not exist**"
    reInviteError: "User re-invite failed. Please try again."
    reInvite: "Re-Invite SubAccount"
    autoReportError: "Delete failed - please unsubscribe user from automatic report."
closeAccount:
    accountManagement: "Account Management"
    deleteAccount: "Delete Account"
    downloadUserData: "Download User Data"
    warning: "Warning: This action is irreversible."
    warningNote: "All data will be permanently deleted and sub accounts removed from the system. Any remaining credits will be forfeit."
    reportNote: "A report containing all bale data will be downloaded for your records. A link to the same report will also be emailed to you. This link will expire in 7 days."
    pleaseConfirm: "Please type 'DELETE' in the box below to continue."
    placeholder: "Type DELETE here"
    confirmation: "CONFIRMED - please remain on the page until the process is complete (this may take a few minutes)."
    reportError: "Error generating report - delete aborted. Please try again or contact support."
    deleteError: "Error deleting account. Please try again or contact support."
    pleaseWait: "Account deletion in progress. Please remain on the page until the process is complete."
    generatingReport: "Generating report"
    deletingAccount: "Deleting account"
    success: "Your account has been successfully deleted"
    thankYou: "Thank you for using BaleLink"
balerManagement:
    subtitle: "Baler Management"
    newBaler: "New Baler"
    addNewBaler: "Add New Baler"
    baler: "Baler"
    vin: "VIN"
    active: "Active"
    acmStatus: "ACM Status"
    balerName: "Baler Name"
    balerNamePlaceholder: "Enter baler name here"
    vinFull: "Vehicle Identification Number"
    vinPlaceholder: "Enter VIN here"
    expiry: "Expiry: {expiry}"
    subscriptionCredits: "Subscription Credits"
    applySubscription: "Apply Subscription"
    balerAlias: "Baler Alias"
    balerAliasPlaceholder: "Your Baler"
    oldBalerAlias: "Old Baler Alias"
    newBalerAlias: "New Baler Alias"
    renameBaler: "Rename Baler"
    deleteBaler: "Delete Baler"
    deleteBalerWarning: "Are you sure you want to delete this baler?"
    deleteBalerWarning2: "This action is irreversible."
    baleLinkSubExpDate: "BaleLink Subscription Expires"
    acmSubExpDate: "ACM Subscription Expires"
    authorizedEmails: "Authorized Emails"
    addAuthorizedEmail: "Add Authorized Email"
    authorizedEmailsDescription: "Balers must possess an ACM subscription under an email registered under this account or an authorized email specified below."
    err:
        notFound: "No balers associated with this account"
        vinAlreadyExists: "VIN already exists"
        emailAlreadyExists: "Email already exists"
subscription:
    baleLinkSubscription: "BaleLink Subscription"
    plansAndPricing: "Plans & Pricing"
    subCreditSloganBeginning: "Get a"
    subCreditSloganMiddle: "free month"
    subCreditSloganEnd: "when activating a new baler!"
    creditNote: "1 CREDIT = 1 MONTH PER BALER"
    transferNote: "TRANSFER BETWEEN BALERS"
    multiYearNote: "USE OVER MULTIPLE YEARS"
    credit: "credit"
    credits: "credits"
    regularPrice: "Regular Price"
    nowOnly: "NOW ONLY"
    recommendedFor: "Recommended For:"
    purchase: Purchase
    purchaseSubscription: "Purchase Subscription"
    applySubInstructions: "Click the months in the year to pre-apply your subscription. Your subscriptions will become active automatically and will last for the full duration of the month. Months coloured in light orange are deemed active and cannot be de-selected."
    applySubConfirmation: "Activating a subscription for the current month cannot be undone. Are you sure you want to proceed?"
    promoHeader: "END OF SEASON SALE"
    promoSubheaderLine1: "SAVE {value}%"
    promoSubheaderLine2: "ON ALL CREDITS"
    promoTagline: "SAVE STACKS OF STACKS ON YOUR STACKS"
    promoEnds: "SALE ENDS {date}"
    buyNow: "Buy {value} Credits"
dataWindow:
    yearSelection: "Year Selection"
    yearSelectionDesc: "Select the crop year(s) you would like to view."
    selectStartMonth: "Crop Year Start Month"
    selectStartMonthDesc: "Which month does your crop year start?"
summary:
    summary: Summary
    summarySubtitle: This is an aggregated summary of your bales within the field of view.
    uid: "UID"
    tagSerialNumber: "Tag Serial Number"
    creationTime: "Creation Time"
    creationLocation: "Creation Location"
    updateTime: "Update Time"
    currentLocation: "Current Location"
    weightKg: "Weight"
    dryMassKg: "Dry Mass"
    avgMoisture: "Average Moisture"
    peakMoisture: "Peak Moisture"
    protein: "Protein"
    ash: "Ash"
    sugar: "NFC"
    temperature: "Temperature"
    measurementQuality: "Measurement Quality (RFV)"
    baleLengthMeters: "Bale Length"
    baleDiameter: "Bale Diameter"
    cutLengthMM: "Cut Length"
    flakeCount: "Flake Count"
    appliedPreservative: "Applied Preservative"
    baleSize: "Bale Size"
    rfv: "RFV"
    adf: "ADF"
    ndf: "NDF"
    balerVin: "Baler VIN"
    name: "Name"
    numberOfBales: "Number of Bales"
    numberOfBalesWithValidWeight: "Bales with Valid Weight"
    weight: "Bale Weight Total"
    avgWeight: "Bale Weight Average"
    minBaleLength: "Bale Length"
    numberOfBalesWithValidAvgMoisture: "Bales with Valid Moisture"
    stackType: "Stack Type"
    avgRFV: "Average RFV"
    bindingMode: "Binding Mode"
    wrapsPerBale: "Wraps per Bale"
login:
    welcomeTitle: "Welcome to BaleLink"
    welcomeSubtitle: "Streamline, track, and optimize your bale management"
    organizationName: "Organization Name"
    organizationNameHint: "Must be at least 3 characters long"
    password: "Password"
    confirmPassword: "Confirm Password"
    passwordRequirements: "Password must: be at least 8 characters long and contain at least 1 number, 1 lowercase letter, and 1 uppercase letter."
    passwordHintLength: "8-character minimum length"
    passwordHintNumber: "Contain at least 1 number"
    passwordHintLowercase: "Contain at least 1 lowercase letter"
    passwordHintUppercase: "Contain at least 1 uppercase letter"
    passwordConfirmHint: "Passwords do not match"
    ulaAgreement: "I have read and agree to the ULA"
    ulaAgreementEnforce: "You must agree to the ULA"
    confirmationCode: "Confirmation Code"
    resendCode: "Resend Code"
    resetPassword: "Reset Password"
    backToSignIn: "Back to Sign In"
    confirmationHeader: "We Emailed You"
    alreadyRegistered: "Already registered? Sign In to get a new code."
    loginError: "An error occurred. Please try again or contact support."
jan: "January"
feb: "February"
mar: "March"
apr: "April"
may: "May"
jun: "June"
jul: "July"
aug: "August"
sep: "September"
oct: "October"
nov: "November"
dec: "December"
area: Area
feedback:
    feedback: "Feedback"
    menuTitle: "We Want To Hear From You!"
    menuSubtitle: "Your feedback is important to us. Please let us know how we can help!"
    feedbackType: "Feedback Type"
    reportBug: "Reporting a Bug"
    requestFeature: "Requesting a Feature"
    inputName: "Name"
    namePlaceholder: "Enter your name"
    inputEmail: "Email"
    emailPlaceholder: "Enter your email address"
    inputDetails: "What happened?"
    detailsPlaceholder: "Details of your bug or feature request"
    formCancel: "CANCEL"
    formSubmit: "SUBMIT FEEDBACK"
    thankYou: "THANK YOU FOR YOUR FEEDBACK"
    bugResponse: "Your bug report has been submitted"
    featureResponse: "Your feature request has been submitted"
autoReport:
    title: "Automatic Report Settings"
    filterLabel: "Select Filter to Populate Report"
    frequencyLabel: "Report Frequency"
    frequency:
        none: "None"
        nightly: "Nightly"
        weekly: "Weekly"
        monthly: "Monthly"
    timezoneLabel: "Timezone"
    unitLabel: "Measurement Units"
    cuttinglessLabel: "Include Bales Not Assigned to a Cutting"
    emailsLabel: "Select Users to Receive Automatic Reports"
    noFiltersAvailable: "No filters available"
    warning: "**Warning: You have unsaved changes**"
    fetchError: "Error fetching settings. If the issue persists, please contact support."
    saveError: "Error saving settings. If the issue persists, please contact support."
    deleteError: "Error deleting settings. If the issue persists, please contact support."
    tooManyRecipientsError: "Maximum report recipients is 50."
    infoMissingError: "Please fill out all required fields: filter, frequency, and at least one recipient."
