import { ResourcesConfig } from "aws-amplify";

const authConfig: ResourcesConfig = {
	Auth: {
		Cognito: {
			// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
			identityPoolId: "eu-west-1:0f309360-6635-4a9e-854e-75b8b0cada9d",
	
			// OPTIONAL - Amazon Cognito User Pool ID
			userPoolId: "eu-west-1_ADFIS7VuQ",
	
			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
			userPoolClientId: "7hvgp1ko14q2u4mdtl39c85ngk",
		}
	},
};

export default authConfig;
