import { Api } from "@/types/fieldApi";
import { refreshToken } from "./auth";

const fieldApiClient = new Api<{ token?: string }>({
	baseApiParams: { secure: true },
	securityWorker: data =>
		data?.token
			? {
					headers: { Authorization: `Bearer ${data.token}` },
				}
			: {},
	customFetch: async (input, init) => {
		try {
			const token = await refreshToken("access");
			if (token && token !== cachedJwt) {
				initFieldImportAPIClient(token);
				if (init?.headers && "Authorization" in init?.headers) {
					init.headers["Authorization"] = `Bearer ${token}`;
				}
			}

			return await fetch(input, init);
		} catch (err) {
			console.error("Encountered errors while refreshing token", err);
			return Promise.reject(err);
		}
	},
});

let cachedJwt = "";

/**
 * Initializes the fieldApiClient for a given page by supplying necessary
 * credentials such as the authorization token.
 *
 * This method should be triggered on every page navigation.
 */
export function initFieldImportAPIClient(jwt: string) {
	cachedJwt = jwt;
	fieldApiClient.setSecurityData({
		token: cachedJwt,
	});
}

/**
 * Use the API client to interface with an external field import and export API
 * managed by AGCO Randers R&D team based in Denmark.
 */
export const useFieldApi = (): Api<{ token?: string }> => fieldApiClient;
