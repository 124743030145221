/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface APIPaginationMetadata {
	/** @format int32 */
	totalRecords?: number | null;
	/** @format int32 */
	totalPages?: number | null;
	/** @format int32 */
	currentPage?: number | null;
	/** @format int64 */
	lastVisitedId?: number | null;
}

export interface AutoReportSettings {
	/** @format int32 */
	uid?: number | null;
	accountId?: string | null;
	frequency?: string | null;
	filterName?: string | null;
	units?: Unit;
	timeZone?: string | null;
	includeCuttinglessBales?: boolean;
	recipients?: string[] | null;
}

export enum BaleBindingMode {
	Unknown = "Unknown",
	Mesh = "Mesh",
	Twine = "Twine",
	Film = "Film",
	TwineAndMesh = "TwineAndMesh",
	TwineAndFilm = "TwineAndFilm",
}

export interface BaleCollection {
	bales?: BaleInfoSlim[] | null;
	stacks?: StackInfoSlim[] | null;
}

export interface BaleGroupSummaryReport {
	name?: string | null;
	/** @format double */
	from?: number;
	/** @format double */
	to?: number;
	/** @format double */
	value?: number;
}

export interface BaleInStackInfo {
	/** @format int64 */
	uid?: number;
	/** @format double */
	weightKg?: number;
	/** @format int32 */
	avgMoisture?: number;
	/** @format int32 */
	rfv?: number;
	stackUID?: string | null;
	statusFlag?: StatusFlag;
}

export interface BaleInfo {
	accountID?: string | null;
	/** @format double */
	weightKg?: number;
	/** @format double */
	dryMassKg?: number;
	/** @format int32 */
	avgMoisture?: number;
	/** @format int32 */
	peakMoisture?: number;
	/** @format int32 */
	protein?: number;
	/** @format int32 */
	ash?: number;
	/** @format int32 */
	sugar?: number;
	/** @format double */
	temperature?: number;
	/** @format int32 */
	measurementQuality?: number;
	/** @format double */
	baleLengthMeters?: number;
	/** @format int32 */
	cutLengthMM?: number;
	/** @format double */
	appliedPreservative?: number;
	baleSize?: BaleSize;
	/** @format int32 */
	rfv?: number;
	/** @format int32 */
	adf?: number;
	/** @format int32 */
	ndf?: number;
	balerVin?: string | null;
	stackUID?: string | null;
	isManuallyTagged?: boolean;
	statusFlag?: StatusFlag;
	baleType?: BaleType;
	/** @format int32 */
	flakeCount?: number;
	bindingMode?: BaleBindingMode;
	/** @format int32 */
	wrapsPerBale?: number;
	/** @format int64 */
	uid?: number;
	/** @format int32 */
	partialUID?: number;
	tagSerialNumber?: string[] | null;
	/** @format date-time */
	creationTime?: string;
	creationLocation?: Location;
	/** @format date-time */
	updateTime?: string;
	currentLocation?: Location;
}

export interface BaleInfoBaseSummaryReport {
	/** @format int32 */
	numberOfBales?: number;
	/** @format int32 */
	numberOfBalesWithValidWeight?: number;
	/** @format int32 */
	numberOfBalesWithValidAvgMoisture?: number;
	/** @format int32 */
	numberOfBalesWithValidDryMass?: number;
	/** @format double */
	weight?: number;
	/** @format double */
	avgWeight?: number;
	/** @format double */
	dryMass?: number;
	/** @format double */
	avgDryMass?: number;
	/** @format double */
	minBaleLength?: number;
	/** @format double */
	maxBaleLength?: number;
	/** @format double */
	avgMoisture?: number;
	/** @format int32 */
	minMoisture?: number;
	/** @format int32 */
	maxMoisture?: number;
	/** @format double */
	appliedPreservative?: number;
	baleSize?: BaleSizeSummaryReport[] | null;
}

export interface BaleInfoSlim {
	/** @format int64 */
	uid?: number;
	/** @format int32 */
	partialUID?: number;
	accountID?: string | null;
	/** @format date-time */
	updateTime?: string;
	tagSerialNumber?: string[] | null;
	creationLocation?: Location;
	currentLocation?: Location;
	stackUID?: string | null;
	statusFlag?: StatusFlag;
	baleType?: BaleType;
}

export interface BaleInfoSummaryReport {
	isReportCreatedOffLine?: boolean;
	graphReport?: BaleSummaryGraphsReport[] | null;
	balesPerBaler?: BalerBalesReport[] | null;
	/** @format int32 */
	numberOfBales?: number;
	/** @format int32 */
	numberOfBalesWithValidWeight?: number;
	/** @format int32 */
	numberOfBalesWithValidAvgMoisture?: number;
	/** @format int32 */
	numberOfBalesWithValidDryMass?: number;
	/** @format double */
	weight?: number;
	/** @format double */
	avgWeight?: number;
	/** @format double */
	dryMass?: number;
	/** @format double */
	avgDryMass?: number;
	/** @format double */
	minBaleLength?: number;
	/** @format double */
	maxBaleLength?: number;
	/** @format double */
	avgMoisture?: number;
	/** @format int32 */
	minMoisture?: number;
	/** @format int32 */
	maxMoisture?: number;
	/** @format double */
	appliedPreservative?: number;
	baleSize?: BaleSizeSummaryReport[] | null;
}

export enum BaleSize {
	ThreeXThree = "ThreeXThree",
	ThreeXFour = "ThreeXFour",
	FourXFour = "FourXFour",
	SeventyXEighty = "SeventyXEighty",
	SeventyXOneTwenty = "SeventyXOneTwenty",
	FourFeet = "FourFeet",
	FiveFeet = "FiveFeet",
}

export interface BaleSizeSummaryReport {
	baleSize?: BaleSize;
	/** @format int32 */
	numberBales?: number;
}

export interface BaleSummaryGraphsReport {
	name?: string | null;
	groupField?: string | null;
	/** @format double */
	groupRange?: number;
	graphUnits?: Unit;
	baleGroupSummaryReport?: BaleGroupSummaryReport[] | null;
}

export enum BaleType {
	LargeSquare = "LargeSquare",
	Round = "Round",
}

export interface BalerBalesReport {
	balerVIN?: string | null;
	/** @format int32 */
	numberOfBales?: number;
}

export interface Boundary {
	northEast?: Location;
	southWest?: Location;
	northWest?: Location;
	southEast?: Location;
}

export interface ClusterDictionary {
	clusters?: Record<string, Record<string, Record<string, ClusterInfo>>>;
}

export interface ClusterInfo {
	currentLocation?: Location;
	/** @format int32 */
	clusterCount?: number;
}

export interface ClusterPrecisionTree {
	accountID?: string | null;
	/** @format int32 */
	dataWindow?: number;
	/** @format date-time */
	firstUpdate?: string;
	/** @format date-time */
	lastUpdate?: string;
	clusterDictionary?: ClusterDictionary;
}

export interface Crop {
	/** @format int32 */
	uid?: number;
	accountID?: string | null;
	name?: string | null;
	/** @format date-time */
	creationTime?: string;
}

export interface CuttingInfo {
	/** @format int32 */
	uid?: number;
	name?: string | null;
	crop?: Crop;
	accountID?: string | null;
	/** @format date-time */
	updateTime?: string;
	/** @format date-time */
	startTime?: string;
	/** @format date-time */
	endTime?: string;
	fields?: FieldInfo[] | null;
}

export interface DateTimeFilterCriteria {
	range?: DateTimeRange;
	/** @format date-span */
	timeSpan?: string | null;
}

export interface DateTimeRange {
	/** @format date-time */
	minimum?: string;
	/** @format date-time */
	maximum?: string;
}

export interface DoubleRange {
	/** @format double */
	minimum?: number;
	/** @format double */
	maximum?: number;
}

export interface ErrorResponseBody {
	error?: string | null;
	reference?: string | null;
}

export interface FieldCreateRequestBody {
	name?: string | null;
	geometry?: FieldGeometry;
}

export interface FieldEditRequestBody {
	name?: string | null;
	geometry?: FieldGeometry;
}

export interface FieldGeometry {
	outerRing?: Location[] | null;
	innerRings?: Location[][] | null;
}

export interface FieldInfo {
	/** @format uuid */
	uid?: string;
	name?: string | null;
	accountID?: string | null;
	geometry?: FieldGeometry;
	/** @format date-time */
	creationTime?: string;
	/** @format date-time */
	updateTime?: string;
	statusFlag?: StatusFlag;
}

export interface FilterInfo {
	accountID?: string | null;
	name?: string | null;
	/** @format date-time */
	filterCreationTime?: string;
	/** @format date-time */
	updateTime?: string;
	missingTags?: boolean | null;
	creationTime?: DateTimeFilterCriteria;
	creationLocation?: GeofenceInfo;
	currentLocation?: GeofenceInfo;
	weightKg?: DoubleRange;
	avgMoisture?: UInt16Range;
	peakMoisture?: UInt16Range;
	protein?: UInt16Range;
	ash?: UInt16Range;
	sugar?: UInt16Range;
	temperature?: DoubleRange;
	measurementQuality?: UInt16Range;
	appliedPreservative?: DoubleRange;
	baleSize?: BaleSize[] | null;
	rfv?: UInt16Range;
	adf?: UInt16Range;
	ndf?: UInt16Range;
	stackUID?: string[] | null;
	balerVin?: string[] | null;
	loadType?: StackType[] | null;
	fieldUIDs?: string[] | null;
	statusFlag?: StatusFlag;
}

export interface FilterWriteRequestBody {
	accountID?: string | null;
	/** @format date-time */
	filterCreationTime?: string | null;
	/** @format date-time */
	updateTime?: string | null;
	statusFlag?: StatusFlag;
	name?: string | null;
	missingTags?: boolean | null;
	creationTime?: DateTimeFilterCriteria;
	creationLocation?: GeofenceInfo;
	currentLocation?: GeofenceInfo;
	weightKg?: DoubleRange;
	avgMoisture?: UInt16Range;
	peakMoisture?: UInt16Range;
	protein?: UInt16Range;
	ash?: UInt16Range;
	sugar?: UInt16Range;
	temperature?: DoubleRange;
	measurementQuality?: UInt16Range;
	appliedPreservative?: DoubleRange;
	baleSize?: BaleSize[] | null;
	rfv?: UInt16Range;
	adf?: UInt16Range;
	ndf?: UInt16Range;
	stackUID?: string[] | null;
	balerVin?: string[] | null;
	loadType?: StackType[] | null;
	fieldUIDs?: string[] | null;
}

export interface GeofenceInfo {
	accountID?: string | null;
	name?: string | null;
	/** @format date-time */
	updateTime?: string;
	boundary?: Location[] | null;
	/** @format double */
	minLatitude?: number;
	/** @format double */
	maxLatitude?: number;
	/** @format double */
	minLongitude?: number;
	/** @format double */
	maxLongitude?: number;
	statusFlag?: StatusFlag;
}

export interface GetAllBalesResponseBody {
	bales?: string[][] | null;
	pagination?: APIPaginationMetadata;
}

export interface HeatmapBaleCollection {
	looseBales?: HeatmapBaleInfo[] | null;
	stacks?: HeatmapStackInfo[] | null;
}

export interface HeatmapBaleInfo {
	/** @format int64 */
	uid?: number;
	creationLocation?: Location;
	currentLocation?: Location;
	/** @format double */
	weightKg?: number;
	/** @format double */
	avgMoisture?: number;
	/** @format double */
	rfv?: number;
	stackUID?: string | null;
	baleType?: BaleType;
}

export enum HeatmapDisplayType {
	Overlay = "Overlay",
	Icon = "Icon",
}

export enum HeatmapFilterType {
	Weight = "Weight",
	AvgMoisture = "AvgMoisture",
	RFV = "RFV",
}

export interface HeatmapStackInfo {
	uid?: string | null;
	stackType?: StackType;
	baleType?: BaleType;
	currentLocation?: Location;
	/** @format double */
	avgWeight?: number;
	/** @format double */
	avgMoisture?: number;
	/** @format double */
	avgRFV?: number;
}

export interface IconHeatmapExport {
	bales?: IconHeatmapMarker[] | null;
	stacks?: IconHeatmapStack[] | null;
	boundary?: Boundary;
	/** @format double */
	rangeLowerLimit?: number | null;
	/** @format double */
	rangeUpperLimit?: number | null;
	/** @format double */
	rangeMinValue?: number;
	/** @format double */
	rangeMaxValue?: number;
	colorGradientLegend?: Record<string, string>;
}

export interface IconHeatmapMarker {
	/** @format int64 */
	uid?: number;
	currentLocation?: Location;
	/** @format int32 */
	heatmapColorCode?: number;
	baleType?: BaleType;
}

export interface IconHeatmapStack {
	uid?: string | null;
	stackType?: StackType;
	currentLocation?: Location;
	/** @format int32 */
	heatmapColorCode?: number;
	baleType?: BaleType;
}

export interface Location {
	/** @format double */
	latitude?: number;
	/** @format double */
	longitude?: number;
	geohash?: string | null;
}

export interface ManualScanRequestBody {
	epCs?: string[] | null;
	/** @format int64 */
	baleUID?: number;
	/** @format int32 */
	existingTagCount?: number;
}

export interface ModifyCuttingRequestBody {
	name?: string | null;
	/** @format int32 */
	cropId?: number | null;
	/** @format date-time */
	startTime?: string | null;
	/** @format date-time */
	endTime?: string | null;
	fieldIDs?: string[] | null;
}

export interface OverlayHeatmapExport {
	bitmap?: string | null;
	/** @format int32 */
	balesCount?: number;
	boundary?: Boundary;
	/** @format double */
	rangeLowerLimit?: number | null;
	/** @format double */
	rangeUpperLimit?: number | null;
	/** @format double */
	rangeMinValue?: number;
	/** @format double */
	rangeMaxValue?: number;
	colorGradientLegend?: Record<string, string>;
}

export interface StackInfo {
	name?: string | null;
	accountID?: string | null;
	bounds?: Location[] | null;
	stackType?: StackType;
	bales?: BaleInStackInfo[] | null;
	/** @format int32 */
	avgMoisture?: number;
	/** @format int32 */
	avgWeight?: number;
	/** @format int32 */
	avgRFV?: number;
	statusFlag?: StatusFlag;
	baleType?: BaleType;
	uid?: string | null;
	/** @format date-time */
	creationTime?: string;
	/** @format date-time */
	updateTime?: string;
	currentLocation?: Location;
}

export interface StackInfoSlim {
	uid?: string | null;
	name?: string | null;
	accountID?: string | null;
	/** @format date-time */
	updateTime?: string;
	currentLocation?: Location;
	stackType?: StackType;
	statusFlag?: StatusFlag;
	baleType?: BaleType;
}

export enum StackType {
	Loose = "Loose",
	Stack = "Stack",
	InternalLoad = "InternalLoad",
	RetailLoad = "RetailLoad",
}

export enum StatusFlag {
	Ok = "Ok",
	Deleted = "Deleted",
}

export interface UInt16Range {
	/** @format int32 */
	minimum?: number;
	/** @format int32 */
	maximum?: number;
}

export enum Unit {
	Metric = "Metric",
	Imperial = "Imperial",
	US = "US",
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
}

export type RequestParams = Omit<
	FullRequestParams,
	"body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
	securityWorker?: (
		securityData: SecurityDataType | null,
	) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
	extends Response {
	data: D;
	error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = "";
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
		fetch(...fetchParams);

	private baseApiParams: RequestParams = {
		credentials: "same-origin",
		headers: {},
		redirect: "follow",
		referrerPolicy: "no-referrer",
	};

	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
		Object.assign(this, apiConfig);
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected encodeQueryParam(key: string, value: any) {
		const encodedKey = encodeURIComponent(key);
		return `${encodedKey}=${encodeURIComponent(
			typeof value === "number" ? value : `${value}`,
		)}`;
	}

	protected addQueryParam(query: QueryParamsType, key: string) {
		return this.encodeQueryParam(key, query[key]);
	}

	protected addArrayQueryParam(query: QueryParamsType, key: string) {
		const value = query[key];
		return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
	}

	protected toQueryString(rawQuery?: QueryParamsType): string {
		const query = rawQuery || {};
		const keys = Object.keys(query).filter(
			key => "undefined" !== typeof query[key],
		);
		return keys
			.map(key =>
				Array.isArray(query[key])
					? this.addArrayQueryParam(query, key)
					: this.addQueryParam(query, key),
			)
			.join("&");
	}

	protected addQueryParams(rawQuery?: QueryParamsType): string {
		const queryString = this.toQueryString(rawQuery);
		return queryString ? `?${queryString}` : "";
	}

	private contentFormatters: Record<ContentType, (input: any) => any> = {
		[ContentType.Json]: (input: any) =>
			input !== null &&
			(typeof input === "object" || typeof input === "string")
				? JSON.stringify(input)
				: input,
		[ContentType.Text]: (input: any) =>
			input !== null && typeof input !== "string"
				? JSON.stringify(input)
				: input,
		[ContentType.FormData]: (input: any) =>
			Object.keys(input || {}).reduce((formData, key) => {
				const property = input[key];
				formData.append(
					key,
					property instanceof Blob
						? property
						: typeof property === "object" && property !== null
							? JSON.stringify(property)
							: `${property}`,
				);
				return formData;
			}, new FormData()),
		[ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};

	protected mergeRequestParams(
		params1: RequestParams,
		params2?: RequestParams,
	): RequestParams {
		return {
			...this.baseApiParams,
			...params1,
			...(params2 || {}),
			headers: {
				...(this.baseApiParams.headers || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected createAbortSignal = (
		cancelToken: CancelToken,
	): AbortSignal | undefined => {
		if (this.abortControllers.has(cancelToken)) {
			const abortController = this.abortControllers.get(cancelToken);
			if (abortController) {
				return abortController.signal;
			}
			return void 0;
		}

		const abortController = new AbortController();
		this.abortControllers.set(cancelToken, abortController);
		return abortController.signal;
	};

	public abortRequest = (cancelToken: CancelToken) => {
		const abortController = this.abortControllers.get(cancelToken);

		if (abortController) {
			abortController.abort();
			this.abortControllers.delete(cancelToken);
		}
	};

	public request = async <T = any, E = any>({
		body,
		secure,
		path,
		type,
		query,
		format,
		baseUrl,
		cancelToken,
		...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
		const secureParams =
			((typeof secure === "boolean"
				? secure
				: this.baseApiParams.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const queryString = query && this.toQueryString(query);
		const payloadFormatter =
			this.contentFormatters[type || ContentType.Json];
		const responseFormat = format || requestParams.format;

		return this.customFetch(
			`${baseUrl || this.baseUrl || ""}${path}${
				queryString ? `?${queryString}` : ""
			}`,
			{
				...requestParams,
				headers: {
					...(requestParams.headers || {}),
					...(type && type !== ContentType.FormData
						? { "Content-Type": type }
						: {}),
				},
				signal:
					(cancelToken
						? this.createAbortSignal(cancelToken)
						: requestParams.signal) || null,
				body:
					typeof body === "undefined" || body === null
						? null
						: payloadFormatter(body),
			},
		).then(async response => {
			const r = response as HttpResponse<T, E>;
			r.data = null as unknown as T;
			r.error = null as unknown as E;

			const data = !responseFormat
				? r
				: await response[responseFormat]()
						.then(data => {
							if (r.ok) {
								r.data = data;
							} else {
								r.error = data;
							}
							return r;
						})
						.catch(e => {
							r.error = e;
							return r;
						});

			if (cancelToken) {
				this.abortControllers.delete(cancelToken);
			}

			if (!response.ok) throw data;
			return data;
		});
	};
}

/**
 * @title RdsLambda
 * @version 1.0
 */
export class Api<
	SecurityDataType extends unknown,
> extends HttpClient<SecurityDataType> {
	autoreport = {
		/**
		 * No description
		 *
		 * @tags AutomaticReportSettings
		 * @name GetAutomaticReportSettings
		 * @request GET:/autoreport/settings
		 */
		getAutomaticReportSettings: (params: RequestParams = {}) =>
			this.request<AutoReportSettings, ErrorResponseBody>({
				path: `/autoreport/settings`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags AutomaticReportSettings
		 * @name CreateAutomaticReportSettings
		 * @request POST:/autoreport/settings
		 */
		createAutomaticReportSettings: (
			data: AutoReportSettings,
			params: RequestParams = {},
		) =>
			this.request<AutoReportSettings, ErrorResponseBody>({
				path: `/autoreport/settings`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags AutomaticReportSettings
		 * @name UpdateAutomaticReportSettings
		 * @request PUT:/autoreport/settings
		 */
		updateAutomaticReportSettings: (
			data: AutoReportSettings,
			params: RequestParams = {},
		) =>
			this.request<AutoReportSettings, ErrorResponseBody>({
				path: `/autoreport/settings`,
				method: "PUT",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags AutomaticReportSettings
		 * @name DeleteAutomaticReportSettings
		 * @request DELETE:/autoreport/settings/{uid}
		 */
		deleteAutomaticReportSettings: (
			uid: number,
			params: RequestParams = {},
		) =>
			this.request<boolean, ErrorResponseBody>({
				path: `/autoreport/settings/${uid}`,
				method: "DELETE",
				format: "json",
				...params,
			}),
	};
	bales = {
		/**
		 * No description
		 *
		 * @tags Bales
		 * @name GetBalesInBoundary
		 * @request GET:/bales/get
		 */
		getBalesInBoundary: (
			query: {
				/** @format double */
				minLat: number;
				/** @format double */
				minLon: number;
				/** @format double */
				maxLat: number;
				/** @format double */
				maxLon: number;
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				filterName?: string;
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<BaleInfoSlim[], any>({
				path: `/bales/get`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Bales
		 * @name GetAllBales
		 * @request GET:/bales/all
		 */
		getAllBales: (
			query?: {
				filterName?: string;
				fields?: string[];
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				cuttings?: number[];
				includeCLBFor?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<string[][], any>({
				path: `/bales/all`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Bales
		 * @name GetAllBalesPaginated
		 * @request GET:/bales/all-paginated
		 */
		getAllBalesPaginated: (
			query?: {
				filterName?: string;
				fields?: string[];
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				cuttings?: number[];
				includeCLBFor?: number[];
				/** @format int32 */
				currentPage?: number;
				/** @format int32 */
				totalPages?: number;
				/** @format int32 */
				totalRecords?: number;
				/** @format int64 */
				lastVisitedId?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<GetAllBalesResponseBody, any>({
				path: `/bales/all-paginated`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Bales
		 * @name SaveManuallyScannedTags
		 * @request POST:/bales/manual-scan
		 */
		saveManuallyScannedTags: (
			data: ManualScanRequestBody,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/bales/manual-scan`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Heatmap
		 * @name GetHeatmapBales
		 * @request GET:/bales/heatmap/get
		 */
		getHeatmapBales: (
			query: {
				/** @format double */
				minLat: number;
				/** @format double */
				minLon: number;
				/** @format double */
				maxLat: number;
				/** @format double */
				maxLon: number;
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				heatmapDisplayType: HeatmapDisplayType;
				filterName?: string;
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<HeatmapBaleInfo[], any>({
				path: `/bales/heatmap/get`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Heatmap
		 * @name GetHeatmapInputDataset
		 * @request GET:/bales/heatmap/input
		 */
		getHeatmapInputDataset: (
			query: {
				/** @format double */
				minLat: number;
				/** @format double */
				minLon: number;
				/** @format double */
				maxLat: number;
				/** @format double */
				maxLon: number;
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				heatmapDisplayType: HeatmapDisplayType;
				filterName?: string;
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<HeatmapBaleCollection, any>({
				path: `/bales/heatmap/input`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Reports
		 * @name GetSummary
		 * @request GET:/bales/summary
		 */
		getSummary: (
			query?: {
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				filterName?: string;
				stackUID?: string[];
				boundary?: string[];
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<BaleInfoSummaryReport, any>({
				path: `/bales/summary`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Reports
		 * @name GetActiveSummary
		 * @request GET:/bales/summary/active
		 */
		getActiveSummary: (
			query?: {
				minDate?: string;
				/** @format int32 */
				yearStartMonth?: number;
				filterName?: string;
				selectedYears?: number[];
				stackUID?: string[];
				boundary?: string[];
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<BaleInfoBaseSummaryReport, any>({
				path: `/bales/summary/active`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Reports
		 * @name GetHistogram
		 * @request GET:/bales/summarygroupby
		 */
		getHistogram: (
			query: {
				groupByField: string;
				/** @format double */
				groupRange: number;
				unit: Unit;
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				stackUID?: string[];
				filterName?: string;
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<BaleGroupSummaryReport[], any>({
				path: `/bales/summarygroupby`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	baleInfo = {
		/**
		 * No description
		 *
		 * @tags Bales
		 * @name GetBalesByUid
		 * @request GET:/bale-info/get
		 */
		getBalesByUid: (
			query?: {
				uid?: number[];
				rfid?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<BaleInfo[], any>({
				path: `/bale-info/get`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	balesStacks = {
		/**
		 * No description
		 *
		 * @tags Bales
		 * @name GetBalesAndStacks
		 * @request GET:/bales-stacks
		 */
		getBalesAndStacks: (
			query: {
				/** @format double */
				minLat: number;
				/** @format double */
				minLon: number;
				/** @format double */
				maxLat: number;
				/** @format double */
				maxLon: number;
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				filterName?: string;
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<BaleCollection, any>({
				path: `/bales-stacks`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	availableYears = {
		/**
		 * No description
		 *
		 * @tags Bales
		 * @name GetAvailableYears
		 * @request GET:/available-years
		 */
		getAvailableYears: (params: RequestParams = {}) =>
			this.request<number[], any>({
				path: `/available-years`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	clusters = {
		/**
		 * No description
		 *
		 * @tags Clusters
		 * @name GetClustersLegacy
		 * @request GET:/clusters/get
		 */
		getClustersLegacy: (
			query: {
				/** @format double */
				minLat?: number;
				/** @format double */
				minLon?: number;
				/** @format double */
				maxLat?: number;
				/** @format double */
				maxLon?: number;
				/** @format int32 */
				precision: number;
				/** @format int32 */
				dataWindow: number;
			},
			params: RequestParams = {},
		) =>
			this.request<ClusterInfo[], any>({
				path: `/clusters/get`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	clusterTree = {
		/**
		 * No description
		 *
		 * @tags Clusters
		 * @name GetClusterTreeLegacy
		 * @request GET:/cluster-tree/get
		 */
		getClusterTreeLegacy: (
			query: {
				/** @format int32 */
				dataWindow: number;
				filterName?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<ClusterPrecisionTree, any>({
				path: `/cluster-tree/get`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	crops = {
		/**
		 * No description
		 *
		 * @tags Crops
		 * @name GetCrops
		 * @request GET:/crops
		 */
		getCrops: (params: RequestParams = {}) =>
			this.request<Crop[], any>({
				path: `/crops`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Crops
		 * @name CreateCrop
		 * @request POST:/crops
		 */
		createCrop: (
			query?: {
				name?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/crops`,
				method: "POST",
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Crops
		 * @name GetCropByUid
		 * @request GET:/crops/{cropId}
		 */
		getCropByUid: (cropId: number, params: RequestParams = {}) =>
			this.request<Crop, any>({
				path: `/crops/${cropId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Crops
		 * @name UpdateCrop
		 * @request PUT:/crops/{cropId}
		 */
		updateCrop: (
			cropId: number,
			query?: {
				name?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/crops/${cropId}`,
				method: "PUT",
				query: query,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Crops
		 * @name DeleteCrop
		 * @request DELETE:/crops/{cropId}
		 */
		deleteCrop: (cropId: number, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/crops/${cropId}`,
				method: "DELETE",
				...params,
			}),
	};
	cuttings = {
		/**
		 * No description
		 *
		 * @tags Cuttings
		 * @name GetCuttings
		 * @request GET:/cuttings
		 */
		getCuttings: (
			query?: {
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<CuttingInfo[], any>({
				path: `/cuttings`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Cuttings
		 * @name CreateCutting
		 * @request POST:/cuttings
		 */
		createCutting: (
			data: ModifyCuttingRequestBody,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/cuttings`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Cuttings
		 * @name GetAllCuttings
		 * @request GET:/cuttings/all
		 */
		getAllCuttings: (params: RequestParams = {}) =>
			this.request<Record<string, Record<string, CuttingInfo[]>>, any>({
				path: `/cuttings/all`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Cuttings
		 * @name GetCuttingByUid
		 * @request GET:/cuttings/{cuttingId}
		 */
		getCuttingByUid: (cuttingId: number, params: RequestParams = {}) =>
			this.request<CuttingInfo, any>({
				path: `/cuttings/${cuttingId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Cuttings
		 * @name UpdateCutting
		 * @request PUT:/cuttings/{cuttingId}
		 */
		updateCutting: (
			cuttingId: number,
			data: ModifyCuttingRequestBody,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/cuttings/${cuttingId}`,
				method: "PUT",
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Cuttings
		 * @name DeleteCutting
		 * @request DELETE:/cuttings/{cuttingId}
		 */
		deleteCutting: (cuttingId: number, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/cuttings/${cuttingId}`,
				method: "DELETE",
				...params,
			}),
	};
	fields = {
		/**
		 * No description
		 *
		 * @tags Fields
		 * @name GetFields
		 * @request GET:/fields
		 */
		getFields: (params: RequestParams = {}) =>
			this.request<FieldInfo[], any>({
				path: `/fields`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Fields
		 * @name CreateField
		 * @request POST:/fields
		 */
		createField: (
			data: FieldCreateRequestBody,
			params: RequestParams = {},
		) =>
			this.request<FieldInfo, any>({
				path: `/fields`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Fields
		 * @name GetFieldByUid
		 * @request GET:/fields/{fieldId}
		 */
		getFieldByUid: (fieldId: string, params: RequestParams = {}) =>
			this.request<FieldInfo, any>({
				path: `/fields/${fieldId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Fields
		 * @name UpdateField
		 * @request PUT:/fields/{fieldId}
		 */
		updateField: (
			fieldId: string,
			data: FieldEditRequestBody,
			params: RequestParams = {},
		) =>
			this.request<void, any>({
				path: `/fields/${fieldId}`,
				method: "PUT",
				body: data,
				type: ContentType.Json,
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Fields
		 * @name DeleteField
		 * @request DELETE:/fields/{fieldId}
		 */
		deleteField: (fieldId: string, params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/fields/${fieldId}`,
				method: "DELETE",
				...params,
			}),
	};
	filters = {
		/**
		 * No description
		 *
		 * @tags Filters
		 * @name GetFilters
		 * @request GET:/filters/get
		 */
		getFilters: (params: RequestParams = {}) =>
			this.request<FilterInfo[], ErrorResponseBody>({
				path: `/filters/get`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Filters
		 * @name CreateFilter
		 * @request POST:/filters/create
		 */
		createFilter: (
			data: FilterWriteRequestBody,
			params: RequestParams = {},
		) =>
			this.request<FilterWriteRequestBody, ErrorResponseBody>({
				path: `/filters/create`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Filters
		 * @name UpdateFilter
		 * @request PUT:/filters/{name}
		 */
		updateFilter: (
			name: string,
			data: FilterWriteRequestBody,
			params: RequestParams = {},
		) =>
			this.request<FilterWriteRequestBody, ErrorResponseBody>({
				path: `/filters/${name}`,
				method: "PUT",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Filters
		 * @name DeleteFilter
		 * @request DELETE:/filters/{name}
		 */
		deleteFilter: (name: string, params: RequestParams = {}) =>
			this.request<string, ErrorResponseBody>({
				path: `/filters/${name}`,
				method: "DELETE",
				format: "json",
				...params,
			}),
	};
	geofences = {
		/**
		 * No description
		 *
		 * @tags Filters
		 * @name GetGeofencesLegacy
		 * @request GET:/geofences/get
		 */
		getGeofencesLegacy: (params: RequestParams = {}) =>
			this.request<GeofenceInfo[], any>({
				path: `/geofences/get`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	heatmap = {
		/**
		 * No description
		 *
		 * @tags Heatmap
		 * @name GetOverlayHeatmap
		 * @request GET:/heatmap/overlay
		 */
		getOverlayHeatmap: (
			query: {
				/** @format double */
				minLat: number;
				/** @format double */
				minLon: number;
				/** @format double */
				maxLat: number;
				/** @format double */
				maxLon: number;
				filterType: HeatmapFilterType;
				/** @format double */
				spacing: number;
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				filterName?: string;
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
				unit?: Unit;
				/** @format double */
				minSelectedRange?: number;
				/** @format double */
				maxSelectedRange?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<OverlayHeatmapExport, any>({
				path: `/heatmap/overlay`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Heatmap
		 * @name GetIconHeatmap
		 * @request GET:/heatmap/icon
		 */
		getIconHeatmap: (
			query: {
				/** @format double */
				minLat: number;
				/** @format double */
				minLon: number;
				/** @format double */
				maxLat: number;
				/** @format double */
				maxLon: number;
				filterType: HeatmapFilterType;
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				filterName?: string;
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
				unit?: Unit;
				/** @format double */
				minSelectedRange?: number;
				/** @format double */
				maxSelectedRange?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<IconHeatmapExport, any>({
				path: `/heatmap/icon`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	reports = {
		/**
		 * No description
		 *
		 * @tags Reports
		 * @name GetCsv
		 * @request GET:/reports/csv
		 */
		getCsv: (
			query?: {
				minDate?: string;
				/** @format int32 */
				yearStartMonth?: number;
				filterName?: string;
				unit?: Unit;
				selectedYears?: number[];
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
				/** @default false */
				includeNutritionData?: boolean;
				/**
				 * @format int32
				 * @default 0
				 */
				timeZoneOffset?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<string, any>({
				path: `/reports/csv`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	stacks = {
		/**
		 * No description
		 *
		 * @tags Stacks
		 * @name GetStacksLegacy
		 * @request GET:/stacks/get
		 */
		getStacksLegacy: (
			query?: {
				/** @format double */
				minLat?: number;
				/** @format double */
				minLon?: number;
				/** @format double */
				maxLat?: number;
				/** @format double */
				maxLon?: number;
				minDate?: string;
				selectedYears?: number[];
				/** @format int32 */
				yearStartMonth?: number;
				filterName?: string;
				fields?: string[];
				cuttings?: number[];
				includeCLBFor?: number[];
			},
			params: RequestParams = {},
		) =>
			this.request<StackInfoSlim[], any>({
				path: `/stacks/get`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	stackInfo = {
		/**
		 * No description
		 *
		 * @tags Stacks
		 * @name GetStacksByUiDsLegacy
		 * @request GET:/stack-info/get
		 */
		getStacksByUiDsLegacy: (
			query?: {
				uid?: string[];
			},
			params: RequestParams = {},
		) =>
			this.request<StackInfo[], any>({
				path: `/stack-info/get`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
}
